import { isAxiosError } from 'axios';
import { call, delay, put, select } from 'typed-redux-saga';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { Socket } from 'socket.io-client';
import { NodeViewActivityData, ProgramData } from '@strivr/player-models';
import { AssetManagementApi } from '../../../../utils/api';

import experienceReducer, { getInitialExperience } from './sliceReducer';
import uiReducer from '../ui/sliceReducer';
import undoReducer, {
  initialState as initialUndoState,
} from '../undo/sliceReducer';
import operationReducer, {
  getInitialOperationsState,
  OperationContainersState,
} from '../operationContainers/sliceReducer';
import operationSaga from '../operationContainers/sliceSaga';
import sceneReducer, {
  getInitialSceneState,
} from '../sceneProperties/sliceReducer';
import nodeReducer, { getInitialNodeState } from '../nodes/sliceReducer';
import previewReducer from '../preview/sliceReducer';
import assetSaga from '../assets/sliceSaga';
import assetReducer from '../assets/sliceReducer';
import previewSaga from '../preview/sliceSaga';

import {
  Asset,
  CGMetadata,
  ObjectMetadata,
  SceneProperties,
  AssetTestModel,
  AssetTestResult,
  EnvironmentProperties,
} from '../../types/models';

import { logSagaError } from '../../../../utils/saga';
import { State } from '../../../../state/reducer';
import {
  DisplayData,
  ExperienceRecord,
  NodeDisplayData,
  PortalData,
  SceneDisplayData,
} from '../../types/json';
import { mapOperationContainerToJSON } from '../../../../utils/mapOperations';

import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit';
import {
  emptyGuid,
  generateGuid,
  getAllIncrementingIds,
  resetIncrementingIds,
  restoreIncrementingIdsFromDisplayData,
} from '../../../../utils/id';
import { filterNodes, filterScenes } from '../../../../utils/node';
import { experienceRecordToStateMapping, relativePath } from './utils';
import { getFileExtension } from '../../../../utils/getFileExtension';
import {
  CONTENT_VERSION_FLAGS,
  DEFAULT_EXPERIENCE_NAME,
  VECTOR_3_DEFAULT_VALUES_BY_FEATURE,
} from '../../constants';
import { FeedbackStyle, OperationType } from '../../enums/models';
import {
  AnimationOperation,
  CharacterLineOperation,
  LearnerResponseOperation,
  McqOperation,
  Operation,
  PlaybackOperation,
  PlaybackPhaseOperation,
  VideoOperation,
} from '../../types/operations';
import { getSignedUrlFromData, SignedUrlAssetData } from '../preview/utils';
import {
  ErrorCheckByTypes,
  ErrorCheckData,
  ExperiencePropertiesData,
} from '../../components/errorCheckList';
import { IconType } from '../../components/nodes/warningIcon';
import { NodeType } from '../../types/nodeData';
import { logToServer } from '../../../../utils/logging';
import {
  assignExperience,
  unassignExperience,
} from '../../../../hooks/websocket/utils';
import { getToggleFromLDClient } from '../../../../utils/toggle';
import { simpleHash } from '../../../../utils/hash';
import {
  checkContentVersion,
  getContentVersion,
} from '../../../../utils/contentVersion';
import { findVersionedAssetInfo } from '../assets/utils';

const ASSET_BUNDLE_PATH = 'AssetBundleObjects\\';
const SOUND_PATH = 'Sounds\\';
const IMAGE_PATH = 'Images\\';
const VIDEO_PATH = 'Videos\\';

const ASSET_BUNDLE_EXTENSION = '.assetbundle';
const RETRY_ON_STATUS_CODES = [408, 413, 429, 500, 502, 503, 504];
const RETRY_TIMEOUT_MS = 15000;

interface LoadExperience {
  experienceVersionId: string;
  prefetchAssets?: boolean;
}

interface CreateExperience {
  isGenericCharacterRig: boolean;
  isCollaborationEnabled: boolean;
  socket: Socket | null;
}

interface SaveExperience {
  experienceRename?: string;
  // If this is disabled, experiences will only save if their
  // hash has changed.
  forceSave?: boolean;
}

interface CloneExperience {
  destinationTenant: string;
  newName: string;
}

interface RestoreExperienceFromRecord {
  errorKey: 'loadExperience' | 'undo' | 'redo';
  experienceRecord: ExperienceRecord;
  preserveSelection: boolean;
  setAnimationLibrary?: boolean;
  experienceVersionId?: string;
  prefetchAssets?: boolean;
}

const mapCharIdToJson = (
  state: State,
  scene: SceneProperties,
  cgMetadata: CGMetadata | undefined,
  characterStance: string,
  startingBodyLanguage: string,
  startingFacialExpression: string,
  treatErrorsAsWarnings?: boolean,
  signedUrlAssetData?: SignedUrlAssetData | null,
): NodeViewActivityData.EntityData | null => {
  const contentVersion = state.main.experience.content_version;
  const characterAssetBundleType = checkContentVersion(
    contentVersion,
    CONTENT_VERSION_FLAGS.genericRig,
  )
    ? 'generic-character-assetbundle'
    : 'character-assetbundle';

  if (scene.characterVersionId === '') return null;
  const character = findVersionedAssetInfo(
    state,
    characterAssetBundleType,
    scene.characterVersionId,
  );
  if (!character) {
    const errmsg = `Character asset missing versionId for scene ${scene.name}`;
    if (treatErrorsAsWarnings) {
      logToServer('warn', errmsg);
      return null;
    }
    throw new Error(errmsg);
  }

  const defaultPosition = cgMetadata
    ? {
        x: cgMetadata.CharacterTransforms[0].Position.X,
        y: cgMetadata.CharacterTransforms[0].Position.Y,
        z: cgMetadata.CharacterTransforms[0].Position.Z,
      }
    : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.character.position;
  const defaultRotation = cgMetadata
    ? {
        x: cgMetadata.CharacterTransforms[0].Rotation.X,
        y: cgMetadata.CharacterTransforms[0].Rotation.Y,
        z: cgMetadata.CharacterTransforms[0].Rotation.Z,
      }
    : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.rotation;
  const defaultScale = cgMetadata
    ? {
        x: cgMetadata.CharacterTransforms[0].Scale.X,
        y: cgMetadata.CharacterTransforms[0].Scale.Y,
        z: cgMetadata.CharacterTransforms[0].Scale.Z,
      }
    : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.scale;

  return {
    // TODO: this needs to be replaced by a real numbering system when we start adding more characters to scenes - for MVP it will always be 0.
    // So in some ways this is more similar to an index than a guid identifier, but not always: if we add multiple characters and delete any,
    // they can all keep their original id's.
    id: 0,
    name: character.name,
    type: 'evolve',
    entity_asset: {
      asset_type: 'AssetBundleObject',
      asset_id: character.versionId,
      name: character.name,
      ...(!signedUrlAssetData
        ? {
            relative_path: relativePath(
              ASSET_BUNDLE_PATH,
              character.versionId,
              ASSET_BUNDLE_EXTENSION,
            ),
          }
        : {
            url: getSignedUrlFromData(
              character.versionId,
              signedUrlAssetData,
              characterAssetBundleType,
            ),
          }),
      fully_qualified_type_name: 'Strivr.Player.Model.AssetBundleObject',
    },
    transform_data: {
      position_data: {
        x: scene.characterPosition.x ?? defaultPosition.x,
        y: scene.characterPosition.y ?? defaultPosition.y,
        z: scene.characterPosition.z ?? defaultPosition.z,
      },
      rotation_data: {
        x: scene.characterRotation.x ?? defaultRotation.x,
        y: scene.characterRotation.y ?? defaultRotation.y,
        z: scene.characterRotation.z ?? defaultRotation.z,
      },
      scale_data: {
        x: scene.characterScale.x ?? defaultScale.x,
        y: scene.characterScale.y ?? defaultScale.y,
        z: scene.characterScale.z ?? defaultScale.z,
      },
    },
    starting_animation_name: scene.startingAnimationNameForCharacter,
    starting_legs_idle_animation_name: characterStance,
    starting_arms_idle_animation_name: startingBodyLanguage,
    starting_face_idle_animation_name: startingFacialExpression,
  };
};

const mapEnvironmentToJSON = (
  state: State,
  env: EnvironmentProperties,
  signedUrlAssetData?: SignedUrlAssetData | null,
): NodeViewActivityData.EnvironmentData => {
  //Get the proper assetbundle from state according to the version id
  const environmentAssetBundle = findVersionedAssetInfo(
    state,
    'environment-assetbundle',
    env.environmentAssetVersionId,
  );
  const soundAsset = findVersionedAssetInfo(
    state,
    'sound',
    env.bgAudioAssetVersionId,
  );
  const videoAsset = findVersionedAssetInfo(
    state,
    'video',
    env.bgVideoAssetVersionId,
  );
  const soundAssetJSON: NodeViewActivityData.Sound | undefined = soundAsset
    ? {
        asset_type: 'Sound',
        asset_id: soundAsset.versionId,
        ...(!signedUrlAssetData
          ? {
              relative_path: `${SOUND_PATH}${
                soundAsset.versionId
              }.${getFileExtension(soundAsset.name)}`,
            }
          : {
              url: getSignedUrlFromData(
                soundAsset.versionId,
                signedUrlAssetData,
                'sound',
              ),
            }),
        name: soundAsset.name,
        fully_qualified_type_name: 'Strivr.Player.Model.Sound',
      }
    : undefined;
  const videoAssetJSON: NodeViewActivityData.Video | undefined = videoAsset
    ? {
        asset_type: 'Video',
        asset_id: videoAsset.versionId,
        ...(!signedUrlAssetData
          ? {
              relative_path: `${VIDEO_PATH}${
                videoAsset.versionId
              }.${getFileExtension(videoAsset.name)}`,
            }
          : {
              url: getSignedUrlFromData(
                videoAsset.versionId,
                signedUrlAssetData,
                'video',
              ),
            }),
        name: videoAsset.name,
        fully_qualified_type_name: 'Strivr.Player.Model.Video',
      }
    : undefined;
  return {
    id: env.id,
    environment_asset: environmentAssetBundle
      ? {
          asset_id: environmentAssetBundle.versionId,
          asset_type: 'AssetBundleObject',
          ...(!signedUrlAssetData
            ? {
                relative_path: relativePath(
                  ASSET_BUNDLE_PATH,
                  environmentAssetBundle.versionId,
                  ASSET_BUNDLE_EXTENSION,
                ),
              }
            : {
                url: getSignedUrlFromData(
                  environmentAssetBundle.versionId,
                  signedUrlAssetData,
                  'environment-assetbundle',
                ),
              }),
          name: environmentAssetBundle.name,
          fully_qualified_type_name: 'Strivr.Player.Model.AssetBundleObject',
        }
      : {},
    sound_asset: soundAssetJSON,
    environment_video: videoAssetJSON,
    use_three_sixty_sphere: env.use360Sphere,
    transform_data: {
      position_data: VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.position,
      rotation_data: VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.rotation,
      scale_data: VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.scale,
    },
    fully_qualified_type_name: 'Strivr.Player.Model.EnvironmentData',
  };
};

const mapAvatarIdToJSON = (
  state: State,
  scene: SceneProperties,
  cgMetadata: CGMetadata | undefined,
  signedUrlAssetData?: SignedUrlAssetData | null,
  treatErrorsAsWarnings?: boolean,
): NodeViewActivityData.EntityData | null => {
  const avatarList: ObjectMetadata[] =
    state.main.assets.assetListsByType['avatar-assetbundle'];

  const avatar =
    findVersionedAssetInfo(
      state,
      'avatar-assetbundle',
      scene.avatarVersionId,
    ) ?? avatarList?.[0];
  if (!avatar) {
    const errmsg = `Avatar asset missing versionId for scene ${scene.name}`;
    if (treatErrorsAsWarnings) {
      logToServer('warn', errmsg);
      return null;
    }
    throw new Error(errmsg);
  }

  const position = cgMetadata
    ? {
        x: cgMetadata.CameraTransforms[0].Position.X,
        y: cgMetadata.CameraTransforms[0].Position.Y - 1.3, // Need to subtract 1.3 to get the right height.
        z: cgMetadata.CameraTransforms[0].Position.Z,
      }
    : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.position;
  const rotation = cgMetadata
    ? {
        x: cgMetadata.CameraTransforms[0].Rotation.X,
        y: cgMetadata.CameraTransforms[0].Rotation.Y,
        z: cgMetadata.CameraTransforms[0].Rotation.Z,
      }
    : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.rotation;
  const scale = cgMetadata
    ? {
        x: cgMetadata.CameraTransforms[0].Scale.X,
        y: cgMetadata.CameraTransforms[0].Scale.Y,
        z: cgMetadata.CameraTransforms[0].Scale.Z,
      }
    : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.scale;

  return {
    // Leave the id to default 1 for now. This will change when eventually we need multiple characters per scene.
    id: 1,
    type: 'avatar',
    name: 'avatar',
    gender: null,
    image: null,
    audio_filter: null,
    audio_source_position: null,
    hair_cap_asset: null,
    outfit_name: null,
    appearance: null,
    image_asset: null,
    fully_qualified_type_name: 'Strivr.SoftSkills.Model.EntityData',
    skin_material_asset: null,
    transform_data: {
      position_data: position,
      rotation_data: rotation,
      scale_data: scale,
    },
    entity_asset: {
      asset_type: 'AssetBundleObject',
      asset_id: avatar.versionId,
      name: avatar.name,
      ...(!signedUrlAssetData
        ? {
            relative_path: relativePath(
              ASSET_BUNDLE_PATH,
              avatar.versionId,
              ASSET_BUNDLE_EXTENSION,
            ),
          }
        : {
            url: getSignedUrlFromData(
              avatar.versionId,
              signedUrlAssetData,
              'avatar-assetbundle',
            ),
          }),
      fully_qualified_type_name: 'Strivr.Player.Model.AssetBundleObject',
    },
  };
};

export const mapScenesToJSON = (
  scenes: Record<string, SceneProperties>,
  operationContainerState: OperationContainersState,
  state: State,
  signedUrlAssetData?: SignedUrlAssetData | null,
  treatErrorsAsWarnings?: boolean,
): Array<NodeViewActivityData.NodeViewSceneData> => {
  const sceneJSONs: Array<NodeViewActivityData.NodeViewSceneData> = Object.keys(
    scenes,
  ).map((sceneId) => {
    const scene = scenes[sceneId];
    // Change this when we need to support multiple environments
    const cgMetadataEnv = scene.environments[0];
    const cgMetadata = findVersionedAssetInfo(
      state,
      'environment-assetbundle',
      cgMetadataEnv.environmentAssetVersionId,
    )?.cgMetadata;
    if (cgMetadataEnv.environmentAssetVersionId && !cgMetadata) {
      const errmsg = `Environment asset missing cgMetadata for scene ${scene.name}`;
      if (treatErrorsAsWarnings) logToServer('warn', errmsg);
      else throw new Error(errmsg);
    }
    const defaultCameraRotation = cgMetadata
      ? {
          x: cgMetadata.CameraTransforms[0].Rotation.X,
          y: cgMetadata.CameraTransforms[0].Rotation.Y,
          z: cgMetadata.CameraTransforms[0].Rotation.Z,
        }
      : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.default.rotation;

    const characterJSON = mapCharIdToJson(
      state,
      scene,
      cgMetadata,
      scene.characterStance,
      scene.startingBodyLanguage,
      scene.startingFacialExpression,
      treatErrorsAsWarnings,
      signedUrlAssetData,
    );

    return {
      id: scene.id,
      name: scene.name,
      characters: characterJSON ? [characterJSON] : [],
      environments: scene.environments.map((env) =>
        mapEnvironmentToJSON(state, env, signedUrlAssetData),
      ),
      // Change this when we need to support multiple environments
      // This also could become a separate toggle
      three_dof_head_tracking_only: scene.environments.some(
        (env) => env.use360Sphere,
      ),
      self_avatar: mapAvatarIdToJSON(
        state,
        scene,
        cgMetadata,
        signedUrlAssetData,
        treatErrorsAsWarnings,
      ),
      operation_containers: scene.operationContainerIds.map((containerId) =>
        mapOperationContainerToJSON(
          state.main.experience.content_version as number,
          operationContainerState.containers[containerId],
          operationContainerState.operations,
          state.main.operationContainers,
          scene.id,
          state.main.sceneProperties,
          signedUrlAssetData,
        ),
      ),
      user_camera_position: {
        x: scene.cameraPosition.x ?? undefined,
        y: scene.cameraPosition.y ?? undefined,
        z: scene.cameraPosition.z ?? undefined,
      },
      user_camera_rotation: {
        x: scene.cameraRotation.x ?? defaultCameraRotation.x,
        y: scene.cameraRotation.y ?? defaultCameraRotation.y,
        z: scene.cameraRotation.z ?? defaultCameraRotation.z,
      },
      fully_qualified_type_name: 'Strivr.Player.Model.NodeViewSceneData',
    };
  });
  return sceneJSONs;
};

export const mapAnimationBundleToJSON = (
  animationBundle: Asset | null,
  animationBundlePreviewVersionVariantId: string | null,
  signedUrlAssetData?: SignedUrlAssetData | null,
): NodeViewActivityData.AssetBundleObject | null | undefined => {
  const url = signedUrlAssetData
    ? signedUrlAssetData.signedUrls?.find(
        (signedUrl) =>
          signedUrl.versionVariantId === animationBundlePreviewVersionVariantId,
      )?.signedUrl ?? undefined
    : undefined;

  return {
    asset_type: 'AssetBundleObject',
    asset_id: animationBundle?.id ?? emptyGuid,
    relative_path:
      url !== undefined
        ? undefined
        : ASSET_BUNDLE_PATH +
          (animationBundle?.id ?? emptyGuid) +
          ASSET_BUNDLE_EXTENSION,
    name: animationBundle?.name ?? '',
    fully_qualified_type_name: 'Strivr.Player.Model.AssetBundleObject',
    url,
  };
};

export const mapExperienceStateToJSON = (
  state: State,
  signedUrlAssetData?: SignedUrlAssetData | null,
  treatErrorsAsWarnings = false,
): NodeViewActivityData.NodeViewActivityData => {
  const { experience } = state.main;
  const { scenes } = state.main.sceneProperties;
  const { operationContainers } = state.main;
  const { assetListsByType, versionedAssetList } = state.main.assets;

  const contentVersion = state.main.experience.content_version;
  const animationId = experience.animationBundle?.id;
  const animationAssets = checkContentVersion(
    contentVersion,
    CONTENT_VERSION_FLAGS.genericRig,
  )
    ? assetListsByType['generic-animation-assetbundle']
    : assetListsByType['animation-assetbundle'];
  const previewAsset =
    // First, try to find the animation asset in the list of latest assets
    animationAssets.find(
      (a) =>
        a.versionId === animationId &&
        a.variant.os_platform?.toLowerCase() === 'webgl',
    ) ??
    // Otherwise, try to find it in the list of old assets
    versionedAssetList[animationId ?? '']?.find(
      (a) => a.variant.os_platform?.toLowerCase() === 'webgl',
    );
  if (!previewAsset)
    logToServer('warn', 'Unable to find webgl animation bundle');

  // TODO: Starting Scene ID will get populated
  // at runtime with a util function. Will do
  // in a later task when we flesh out the
  // OperationContainer redux state and behavior
  // as OperationContainers drive the initial
  // loading behavior.
  return {
    name: experience.name,
    version_date: experience.lastModified ?? '',
    date_created: experience.dateCreated ?? '',
    activity_id: experience.id,
    starting_scene_id: experience.startingSceneId ?? emptyGuid,
    starting_container_id: experience.startingOperationContainerId ?? emptyGuid,
    content_version: experience.content_version,
    scenes: mapScenesToJSON(
      scenes,
      operationContainers,
      state,
      signedUrlAssetData,
      treatErrorsAsWarnings,
    ),
    fully_qualified_type_name: 'Strivr.Player.Model.NodeViewActivityData',
    image: experience.image
      ? {
          asset_type: 'Image',
          asset_id: experience.image.id,
          relative_path: `${IMAGE_PATH}${
            experience.image.id
          }.${getFileExtension(experience.image.name)}`,
          name: experience.image.name ?? '',
          fully_qualified_type_name: 'Strivr.Player.Model.Image',
        }
      : null,
    vh_animation_bundle: mapAnimationBundleToJSON(
      experience.animationBundle,
      previewAsset?.versionVariantId ?? null,
      signedUrlAssetData,
    ),
    duration: experience.durationInMinutes,
  };
};

export const mapPortalStateToJSON = (state: State): PortalData => {
  return {
    description: state.main.experience.description,
    durationInMinutes: state.main.experience.durationInMinutes,
  };
};

export const mapDisplayStateToJSON = (state: State): DisplayData => {
  const { elements } = state.main.nodes;

  // Making these mappings explicit, as saved display data should remain backwards compatible if UI data format changes

  const nodes = filterNodes(elements).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: {
        height: cur.data?.height,
        width: cur.data?.width,
        x: cur.position.x,
        y: cur.position.y,
      },
    }),
    {} as Record<string, NodeDisplayData>,
  );

  const scenes = filterScenes(elements).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: {
        height: cur.data?.height,
        x: cur.position.x,
        y: cur.position.y,
        width: cur.data?.width,
      },
    }),
    {} as Record<string, SceneDisplayData>,
  );

  const incrementingIds = getAllIncrementingIds();

  return {
    nodes,
    scenes,
    incrementingIds,
  };
};

export const wrapExperienceInProgramJSON = (
  experience: NodeViewActivityData.NodeViewActivityData,
): ProgramData.ProgramData => {
  const moduleId = generateGuid();
  return {
    id: generateGuid(),
    name: `Web Creator Experience Preview Program - ${experience.name}`,
    folder_id: emptyGuid, // should never be an empty string
    distribution_name: '',
    image: null,
    prompt: '',
    instruction_text: '',
    modules: [
      {
        id: moduleId,
        name: 'Web Creator Experience Preview Module',
        image: null,
        instruction_text: '',
        prompt: '',
        audio: null,
        activity_order: experience.activity_id
          ? { [experience.activity_id]: 1 }
          : {},
        activities: [experience],
        fully_qualified_type_name: 'Strivr.Player.Model.ModuleData',
      },
    ],
    module_order: { [moduleId]: 1 },
    fully_qualified_type_name: 'Strivr.Player.Model.ProgramData',
  };
};

function* fetchSpeechAsset(
  assetId: string | undefined,
  assetType: 'sound' | 'jali-assetbundle' | 'lipsync-assetbundle',
  operation: CharacterLineOperation,
  state: State,
) {
  const latestAsset = state.main.assets.assetListsByType[assetType].find(
    (a) => a.versionId === assetId,
  );
  if (assetId && !latestAsset) {
    yield* put(
      assetSaga.actions.fetchVersionedAsset({
        versionId: assetId,
        type: assetType,
        operation,
      }),
    );
  }
}

// The amount of time to wait before the "published" notification
// disappears, in milliseconds
const PUBLISH_NOTIFICATION_TIMEOUT = 10 * 1000;

const sliceSaga = createSliceSaga({
  name: experienceReducer.name,
  caseSagas: {
    saveExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<SaveExperience>) {
        const field = 'saveExperience';
        const state: State = yield* select();
        try {
          const tenant = state.main.tenants.selectedTenant?.tenant_name;
          const { assetListsByType } = state.main.assets;
          const { lastSave } = state.main.ui;

          const displayData = mapDisplayStateToJSON(state);
          const portalData = mapPortalStateToJSON(state);
          const experience = mapExperienceStateToJSON(state);
          let shouldFetchExperiences = false;
          if (experience.name === DEFAULT_EXPERIENCE_NAME) {
            shouldFetchExperiences = true;
            const experiences = assetListsByType.experience;
            if (experiences) {
              const newName = `${DEFAULT_EXPERIENCE_NAME} ${
                experiences.length + 1
              }`;
              yield* put(
                experienceReducer.actions.update({
                  name: newName,
                }),
              );
              experience.name = newName;
            }
          }

          const experienceToSave: ExperienceRecord = {
            displayData,
            experience,
            portalData,
          };

          const experienceHash = simpleHash(JSON.stringify(experienceToSave));

          /**
           * If nothing has changed since our last save, don't save again.
           * This helps prevent saving from being too aggressive: if we
           * constantly save, it's impossible to pin down the latest version
           * of an experience.
           */
          if (!action.payload.forceSave && lastSave.hash === experienceHash) {
            return;
          }

          const request = AssetManagementApi().assets.uploadAsset(
            {
              id: experience.activity_id,
              tenant: tenant ?? '',
              type: 'experience',
              name: experience.name ?? '',
            },
            experienceToSave,
            'application/json',
          );
          const response = yield* call(request);
          yield* put(experienceReducer.actions.clearErrorsByKey(field));
          yield* put(
            uiReducer.actions.update({
              isDirty: false,
              isNew: false,
              isRetry: false,
              lastSave: {
                savedAt: new Date().toString(),
                hash: experienceHash,
              },
            }),
          );
          if (shouldFetchExperiences) {
            yield* put(assetSaga.actions.fetchAssets('experience'));
          }
          yield* put(
            experienceReducer.actions.update({
              version: parseInt(response.data.version),
              versionId: response.data.versionId,
            }),
          );
        } catch (e) {
          logSagaError(action, e);

          const isRetry =
            isAxiosError(e) &&
            e.response &&
            RETRY_ON_STATUS_CODES.includes(e.response.status);

          yield* put(
            experienceReducer.actions.updateErrors({
              field,
              error: e,
            }),
          );
          yield* put(
            uiReducer.actions.update({
              isDirty: false,
              isRetry,
            }),
          );

          if (isRetry) {
            // Retry after delay
            yield* delay(RETRY_TIMEOUT_MS);

            // Self-referencing breaks typings unless we create a new variable referencing the current saga
            const currentSaga: {
              actions: {
                saveExperience: ActionCreatorWithPayload<SaveExperience>;
              };
            } = sliceSaga;
            yield put(currentSaga.actions.saveExperience({}));
          }
        }
      },
    },

    // TODO: Pending discussion with backend, we might actually not need to post to the backend to create an experience. We can generate
    // the guid and initialization on the frontend and just post the saves to the backend.
    createExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<CreateExperience>) {
        const field = 'createExperience';
        const { isGenericCharacterRig, isCollaborationEnabled, socket } =
          action.payload;
        // Clear out the state that currently exists
        const oldState: State = yield* select();
        try {
          // If we were using another experience, unassign it
          if (isCollaborationEnabled) {
            unassignExperience(socket, oldState.main.experience.id).catch(
              () => {},
            );
          }

          const animationBundleType = isGenericCharacterRig
            ? 'generic-animation-assetbundle'
            : 'animation-assetbundle';
          // If there's an animation bundle built by the asset uploader pipeline,
          // use that one. Otherwise, use the first animation asset available.
          const pipelineBuiltAnimationBundle =
            oldState.main.assets.assetListsByType[animationBundleType].find(
              (aab) => !!aab.commitHash,
            );

          const animationBundle =
            pipelineBuiltAnimationBundle ??
            oldState.main.assets.assetListsByType[animationBundleType][0];

          const { name, versionId } = animationBundle ?? {};
          if (!versionId) throw new Error('Animation asset missing versionId');
          const initialExperience = getInitialExperience();
          initialExperience.version = 1;
          initialExperience.animationBundle = {
            id: versionId,
            assetType: animationBundleType,
            name,
          };
          initialExperience.content_version = getContentVersion(
            isGenericCharacterRig,
          );
          yield* put(undoReducer.actions.update({ undoStateLock: true }));
          yield* put(
            assetReducer.actions.update({ suggestedAssetUpdates: {} }),
          );
          yield* put(experienceReducer.actions.update(initialExperience));
          yield* put(
            operationReducer.actions.update(getInitialOperationsState()),
          );
          yield* put(sceneReducer.actions.update(getInitialSceneState()));
          yield* put(nodeReducer.actions.update(getInitialNodeState()));
          yield* put(undoReducer.actions.update(initialUndoState));
          yield* put(previewReducer.actions.update({ isSceneReady: false }));

          resetIncrementingIds();
          yield* put(experienceReducer.actions.clearErrorsByKey(field));
          yield* put(
            uiReducer.actions.update({
              isNew: true,
              isRetry: false,
              lastSave: {
                savedAt: new Date().toString(),
                hash: 0,
              },
            }),
          );

          if (isCollaborationEnabled) {
            assignExperience(socket, initialExperience.id).catch(() => {});
          }

          yield put(assetSaga.actions.setExperienceAnimationLibrary({}));
        } catch (e) {
          yield* put(
            experienceReducer.actions.updateErrors({
              field,
              error: e,
            }),
          );
          logSagaError(action, e);

          if (
            isAxiosError(e) &&
            e.response &&
            RETRY_ON_STATUS_CODES.includes(e.response.status)
          ) {
            // Retry after delay
            yield* delay(RETRY_TIMEOUT_MS);

            // Self-referencing breaks typings unless we create a new variable referencing the current saga
            const currentSaga: {
              actions: {
                createExperience: ActionCreatorWithPayload<CreateExperience>;
              };
            } = sliceSaga;
            yield put(
              currentSaga.actions.createExperience({
                isGenericCharacterRig,
                isCollaborationEnabled,
                socket,
              }),
            );
          }
        }
      },
    },

    loadExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<LoadExperience>) {
        const field = 'loadExperience';
        const state: State = yield* select();
        try {
          const tenant = state.main.tenants.selectedTenant?.tenant_name;
          const versionId = action.payload.experienceVersionId;

          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );

          const request = AssetManagementApi({
            useGroupLabelBasedAssetRetrieval,
          }).assets.getSingleAsset<ExperienceRecord>(
            {
              tenant,
              version_id: versionId,
            },
            tenant ? [tenant] : undefined,
          );
          const response = yield* call(request);

          const experienceRecord = response.data;

          // Copy the experience in a way that we can delete unnecessary entries
          const hashableExperienceRecord: ExperienceRecord = {
            ...experienceRecord,
            experience: {
              ...experienceRecord.experience,
            },
          };
          // When hashing, remove the version number: otherwise subsequent saves will
          // see the lack of this from saves as a difference.
          delete hashableExperienceRecord.experience.version_number;
          const experienceHash = simpleHash(
            JSON.stringify(hashableExperienceRecord),
          );

          const currentSaga: {
            actions: {
              restoreExperienceFromRecord: ActionCreatorWithPayload<RestoreExperienceFromRecord>;
            };
          } = sliceSaga;
          yield* put(undoReducer.actions.update(initialUndoState));
          yield* put(previewReducer.actions.update({ isSceneReady: false }));
          yield* put(
            uiReducer.actions.update({
              lastSave: {
                // TODO: Loading an experience will show it as "Last saved: Now",
                // when in truth this is not saving it.
                savedAt: new Date().toString(),
                hash: experienceHash,
              },
            }),
          );
          yield put(
            currentSaga.actions.restoreExperienceFromRecord({
              errorKey: field,
              experienceRecord,
              preserveSelection: false,
              setAnimationLibrary: true,
              experienceVersionId: versionId,
              prefetchAssets: action.payload.prefetchAssets,
            }),
          );
        } catch (e) {
          yield* put(
            experienceReducer.actions.updateErrors({
              field,
              error: `Could not load experience: ${(e as Error).message}`,
            }),
          );
          logSagaError(action, e);
        }
      },
    },

    restoreExperienceFromRecord: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<RestoreExperienceFromRecord>) {
        let state: State = yield* select();
        try {
          /**
           * Update every reducer with data from experience JSON:
           *
           * 1. experience
           * 2. nodes
           * 3. sceneProperties
           * 4. operationContainers
           */
          yield* put(undoReducer.actions.update({ undoStateLock: true }));
          yield* put(
            assetReducer.actions.update({ suggestedAssetUpdates: {} }),
          );
          const { elementsCopied, elementsSelected } = state.main.nodes;
          const mappedStateFromExperienceJSON = experienceRecordToStateMapping(
            action.payload.experienceRecord,
            undefined,
            undefined,
            action.payload.experienceVersionId,
          );
          const mappedStateData = mappedStateFromExperienceJSON.data;
          if (mappedStateData) {
            const {
              newExperienceData,
              newNodesData,
              newScenePropertiesData,
              newOperationContainersData,
            } = mappedStateData;
            yield* put(experienceReducer.actions.update(newExperienceData));
            yield* put(
              operationReducer.actions.update(newOperationContainersData),
            );
            yield* put(nodeReducer.actions.update(newNodesData));
            yield* put(sceneReducer.actions.update(newScenePropertiesData));

            restoreIncrementingIdsFromDisplayData(
              action.payload.experienceRecord.displayData,
            );

            yield* put(operationSaga.actions.calculateOperationTimes());
            yield* put(
              experienceReducer.actions.clearErrorsByKey(
                action.payload.errorKey,
              ),
            );
          } else {
            const errorMessage = mappedStateFromExperienceJSON.error;
            if (errorMessage) {
              throw new Error(errorMessage);
            }
          }

          state = yield* select();
          const { scenes } = state.main.sceneProperties;
          const { operations } = state.main.operationContainers;
          const { experience } = state.main;
          const contentVersion = experience.content_version;

          if (experience.animationBundle) {
            const animationBundleType = checkContentVersion(
              contentVersion,
              CONTENT_VERSION_FLAGS.genericRig,
            )
              ? 'generic-animation-assetbundle'
              : 'animation-assetbundle';
            const latestAnimationBundle = state.main.assets.assetListsByType[
              animationBundleType
            ].find((a) => a.versionId === experience.animationBundle?.id);
            if (!latestAnimationBundle) {
              yield* put(
                assetSaga.actions.fetchVersionedAsset({
                  versionId: experience.animationBundle.id,
                  type: animationBundleType,
                  experience,
                }),
              );
            }
          }

          const characterBundleType = checkContentVersion(
            contentVersion,
            CONTENT_VERSION_FLAGS.genericRig,
          )
            ? 'generic-character-assetbundle'
            : 'character-assetbundle';
          for (const id in scenes) {
            const scene = scenes[id];
            if (scene.characterVersionId) {
              const latestCharacterAssetBundle =
                state.main.assets.assetListsByType[characterBundleType].find(
                  (a) => a.versionId === scene.characterVersionId,
                );
              if (!latestCharacterAssetBundle) {
                yield* put(
                  assetSaga.actions.fetchVersionedAsset({
                    versionId: scene.characterVersionId,
                    type: characterBundleType,
                    scene,
                  }),
                );
              }
            }

            for (const env of scene.environments) {
              if (env.environmentAssetVersionId) {
                const latestEnvironmentAssetBundle =
                  state.main.assets.assetListsByType[
                    'environment-assetbundle'
                  ].find((a) => a.versionId === env.environmentAssetVersionId);
                if (!latestEnvironmentAssetBundle) {
                  yield* put(
                    assetSaga.actions.fetchVersionedAsset({
                      versionId: env.environmentAssetVersionId,
                      type: 'environment-assetbundle',
                      scene,
                    }),
                  );
                }
              }
            }

            if (scene.avatarVersionId) {
              const latestAvatarAssetBundle =
                state.main.assets.assetListsByType['avatar-assetbundle'].find(
                  (a) => a.versionId === scene.avatarVersionId,
                );
              if (!latestAvatarAssetBundle) {
                yield* put(
                  assetSaga.actions.fetchVersionedAsset({
                    versionId: scene.avatarVersionId,
                    type: 'avatar-assetbundle',
                    scene,
                  }),
                );
              }
            }

            for (const env of scene.environments) {
              const { bgAudioAssetVersionId, bgVideoAssetVersionId } = env;
              if (bgAudioAssetVersionId) {
                const latestEnvironmentBackgroundAudio =
                  state.main.assets.assetListsByType.sound.find(
                    (a) => a.versionId === bgAudioAssetVersionId,
                  );
                if (!latestEnvironmentBackgroundAudio) {
                  yield* put(
                    assetSaga.actions.fetchVersionedAsset({
                      versionId: bgAudioAssetVersionId,
                      type: 'sound',
                      scene,
                    }),
                  );
                }
              }
              if (bgVideoAssetVersionId) {
                const latestEnvironmentBackgroundVideo =
                  state.main.assets.assetListsByType.video.find(
                    (a) => a.versionId === bgVideoAssetVersionId,
                  );
                if (!latestEnvironmentBackgroundVideo) {
                  yield* put(
                    assetSaga.actions.fetchVersionedAsset({
                      versionId: bgVideoAssetVersionId,
                      type: 'video',
                      scene,
                    }),
                  );
                }
              }
            }
          }

          for (const id in operations) {
            const operation = operations[id];
            switch (operation.type) {
              case OperationType.NotificationDialog2:
                if (operation.audio) {
                  const latestSoundAsset =
                    state.main.assets.assetListsByType.sound.find(
                      (a) => a.versionId === operation.audio?.id,
                    );
                  if (!latestSoundAsset) {
                    yield* put(
                      assetSaga.actions.fetchVersionedAsset({
                        versionId: operation.audio.id,
                        type: 'sound',
                        operation,
                      }),
                    );
                  }
                }
                if (operation.image) {
                  const latestImageAsset =
                    state.main.assets.assetListsByType.image.find(
                      (a) => a.versionId === operation.image?.id,
                    );
                  if (!latestImageAsset) {
                    yield* put(
                      assetSaga.actions.fetchVersionedAsset({
                        versionId: operation.image.id,
                        type: 'image',
                        operation,
                      }),
                    );
                  }
                }
                break;
              case OperationType.CharacterLine:
                if (operation.vhSpeaks.lipSyncAsset)
                  yield* fetchSpeechAsset(
                    operation.vhSpeaks.lipSyncAsset?.id,
                    'lipsync-assetbundle',
                    operation,
                    state,
                  );
                else if (operation.vhSpeaks.jaliAsset)
                  yield* fetchSpeechAsset(
                    operation.vhSpeaks.jaliAsset?.id,
                    'jali-assetbundle',
                    operation,
                    state,
                  );
                else if (operation.vhSpeaks.soundAsset)
                  yield* fetchSpeechAsset(
                    operation.vhSpeaks.soundAsset?.id,
                    'sound',
                    operation,
                    state,
                  );

                break;
            }
          }

          if (action.payload.preserveSelection) {
            const finalState: State = yield* select();
            yield* put(
              nodeReducer.actions.update({
                elementsCopied,
                elementsSelected: elementsSelected.filter((es) =>
                  finalState.main.nodes.elements.some((e) => e.id === es.id),
                ),
              }),
            );
          }

          if (action.payload.setAnimationLibrary) {
            yield put(assetSaga.actions.setExperienceAnimationLibrary({}));
          }

          if (action.payload.prefetchAssets) {
            yield put(previewSaga.actions.precacheAllSignedUrlsInExperience());
          }
        } catch (e) {
          yield* put(
            experienceReducer.actions.updateErrors({
              field: action.payload.errorKey,
              error: `Could not restore experience data: ${
                (e as Error).message
              }`,
            }),
          );
          logSagaError(action, e);
        } finally {
          yield* put(undoReducer.actions.update({ undoStateLock: false }));
        }
      },
    },

    cloneExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<CloneExperience>) {
        const field = 'cloneExperience';
        const state: State = yield* select();
        const { versionId } = state.main.experience;
        const sourceTenant = state.main.tenants.selectedTenant?.tenant_name;

        try {
          yield* put(
            experienceReducer.actions.update({
              isCloning: true,
            }),
          );
          const { destinationTenant, newName } = action.payload;

          if (!sourceTenant) {
            throw new Error('No source tenant found');
          }
          if (!versionId) {
            throw new Error('versionId not found');
          }
          if (!newName) {
            throw new Error('Must have new experience name');
          }

          // Save current experience as it is now
          // Self-referencing breaks typings unless we create a new variable referencing the current saga
          const currentSaga: {
            actions: {
              saveExperience: ActionCreatorWithPayload<SaveExperience>;
            };
          } = sliceSaga;
          yield put(currentSaga.actions.saveExperience({}));

          const request = AssetManagementApi().assets.cloneAsset(
            versionId,
            sourceTenant,
            destinationTenant || sourceTenant,
            newName,
          );
          yield* call(request);
        } catch (e) {
          yield* put(
            experienceReducer.actions.update({
              isCloning: false,
              isCloneSuccessful: false,
            }),
          );
          yield* put(
            experienceReducer.actions.updateErrors({
              field,
              error: `Could not clone experience: ${(e as Error).message}`,
            }),
          );
          logSagaError(action, e);
        } finally {
          yield* put(
            experienceReducer.actions.update({
              isCloning: false,
              isCloneSuccessful: true,
            }),
          );
        }
      },
    },

    publishExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const field = 'publishExperience';
        const state: State = yield* select();
        const { versionId } = state.main.experience;
        const tenant = state.main.tenants.selectedTenant?.tenant_name;

        if (!versionId) {
          yield* put(
            experienceReducer.actions.updateErrors({
              field,
              error: `Publish failed: Experience is not fully loaded yet. Please wait before publishing.`,
            }),
          );

          return;
        }

        try {
          yield* put(
            experienceReducer.actions.update({
              isPublishing: true,
            }),
          );
          const request = AssetManagementApi().publish.publishExperience(
            versionId,
            tenant ?? '',
          );
          yield* call(request);
          yield* put(
            experienceReducer.actions.update({
              // Even though we set `isPublishing` to `false` afterwards,
              // we set it here so that we don't have to wait until
              // the notification is cleared to unset this.
              isPublishing: false,
              isPublishSuccessful: true,
            }),
          );
          yield* put(experienceReducer.actions.clearErrorsByKey(field));
          // After the specified time, clear away the success notification
          yield* delay(PUBLISH_NOTIFICATION_TIMEOUT);
          yield* put(
            experienceReducer.actions.update({
              isPublishSuccessful: false,
            }),
          );
        } catch (e) {
          logSagaError(action, e);
          yield* put(
            experienceReducer.actions.update({
              isPublishSuccessful: false,
            }),
          );
          yield* put(
            experienceReducer.actions.updateErrors({
              field,
              error: `Publish failed: ${
                isAxiosError(e) ? e.response?.data : JSON.stringify(e)
              }`,
            }),
          );
        } finally {
          yield* put(
            experienceReducer.actions.update({
              isPublishing: false,
            }),
          );
        }
      },
    },

    setErrorCheckListFromExperience: {
      sagaType: SagaType.TakeLatest,
      *fn() {
        const state: State = yield* select();
        const { experience } = state.main;

        const { scenes } = state.main.sceneProperties;
        const { operations, containers } = state.main.operationContainers;

        const sceneEntries = Object.values(scenes);
        const operationEntries = Object.values(operations);

        const errorCheckListByType: ErrorCheckByTypes = {};

        let totalWarnings = 0;
        let totalMandatoryWarnings = 0;
        const getErrorCheckEntryFromOperation = (
          data: Operation | SceneProperties,
        ): ErrorCheckData => {
          let status = '';
          let warningCount = 0;
          let mandatoryCount = 0;
          let mandatoryStatus = '';
          let emptyName = false;
          let icon: IconType = 'Check';
          let type: NodeType | undefined;

          const updateMandatoryWarnings = (newMandatoryStatus: string) => {
            totalMandatoryWarnings++;
            mandatoryCount++;
            mandatoryStatus = newMandatoryStatus;
          };

          const updateWarnings = (newStatus: string) => {
            totalWarnings++;
            warningCount++;
            status = newStatus;
          };

          // [Mandatory] Each entry must have a name
          if (data.name.match(/^ *$/) !== null) {
            emptyName = true;
            updateMandatoryWarnings('Operation name required');
          }

          const operation = data as Operation;
          const scene = data as SceneProperties;
          const container = operation.containerId
            ? containers[operation.containerId]
            : null;
          const parentScene = sceneEntries.find((s) =>
            s.operationContainerIds.includes(operation.containerId),
          );

          if (operation.type) {
            if (
              operation.type !== OperationType.PlaybackPhase &&
              operation.type !== OperationType.PlaybackNode
            ) {
              // if it's in a stack
              if (container && container.operationIds.length > 1) {
                switch (operation.type) {
                  case OperationType.MultipleChoiceQuestion: {
                    const nextNodeCount = operation.children.filter(
                      (c) => c.doesNextNodeExist,
                    ).length;
                    if (operation.isOutputEnabled && nextNodeCount === 0) {
                      updateWarnings(
                        'Output connection in stack is unused, at least one MCQ answer should utilize it',
                      );
                    }
                    break;
                  }
                  case OperationType.Video: {
                    const containerOps = container.operationIds.map(
                      (opid) => operations[opid],
                    );
                    const videoOps = containerOps.filter(
                      (op) => op.type === OperationType.Video,
                    );
                    if (videoOps.length > 1) {
                      updateMandatoryWarnings(
                        'Stack cannot have more than 1 video node',
                      );
                    }
                    break;
                  }
                }

                if (
                  operation.type !== OperationType.Video &&
                  operation.type !== OperationType.MultipleChoiceQuestion &&
                  operation.isOutputEnabled &&
                  !operation.doesNextNodeExist
                ) {
                  updateWarnings('Output connection in stack is unused');
                }
              }
            }
            switch (operation.type) {
              case OperationType.Animation: {
                // Each animation node has at least one animation selected
                const animation = operation as AnimationOperation;
                type = 'animation';
                if (animation.animation.clipName.match(/^ *$/) !== null) {
                  updateWarnings('No Character Animation selected');
                }
                break;
              }
              case OperationType.CharacterLine: {
                const characterLine = operation as CharacterLineOperation;
                type = 'character line';

                // [Mandatory] Has transcription, voiceover, OR lipsync attached
                if (
                  characterLine.vhSpeaks.lipSyncAsset === null &&
                  characterLine.vhSpeaks.soundAsset === null &&
                  characterLine.vhSpeaks.jaliAsset === null
                ) {
                  updateMandatoryWarnings(
                    'Character Line must have a sound or lipsync file',
                  );
                }
                if (
                  characterLine.vhSpeaks.textResponse.match(/^ *$/) !== null
                ) {
                  updateMandatoryWarnings(
                    'Character Line must have a transcript',
                  );
                }
                break;
              }
              case OperationType.MultipleChoiceQuestion: {
                const mcq = operation as McqOperation;
                type = 'mcq';

                // [Mandatory] Questions & answer texts filled in (text)
                if (mcq.text.match(/^ *$/) !== null) {
                  updateMandatoryWarnings('Question Text required');
                }

                // MCQ feedback type set (answer feedback style)
                if (mcq.answerFeedbackStyle === FeedbackStyle.NotSet) {
                  updateWarnings('Answer type is not set');
                }

                // MCQ Answer text required
                let mcqChildrenWarnings = 0;
                mcq.children.forEach((c) => {
                  if (c.text.match(/^ *$/) !== null) {
                    mcqChildrenWarnings++;
                  }
                });
                if (mcqChildrenWarnings > 0) {
                  updateWarnings('Empty answer text');
                }
                break;
              }
              case OperationType.LearnerResponse: {
                const lr = operation as LearnerResponseOperation;
                type = 'learner response';
                // Has a skills Framework selected
                if (lr.skillsFrameworks.length === 0) {
                  updateWarnings('Skills Framework not selected');
                }
                break;
              }
              case OperationType.PlaybackNode: {
                const playback = operation as PlaybackOperation;
                type = 'playback';

                // At least one node selected
                if (playback.phasesToPlayback.length === 0) {
                  updateWarnings('No Playback Phase selected');
                }
                break;
              }
              case OperationType.PlaybackPhase: {
                const pp = operation as PlaybackPhaseOperation;
                type = 'playback phase';

                if (pp.flipCamera && !parentScene!.characterVersionId) {
                  updateWarnings(
                    'Playback is set to flip camera but there is no character',
                  );
                }
                break;
              }
              case OperationType.NotificationDialog2: {
                type = 'overlay';

                break;
              }
              case OperationType.Video: {
                const videoOperation = operation as VideoOperation;
                type = 'video';

                // Has a video selected
                if (!videoOperation.video) {
                  updateMandatoryWarnings('No video asset selected');
                }

                break;
              }
            }
          } else if (scene) {
            type = 'scene';

            // Each Scene has an environment, character, and avatar selected
            if (scene.avatarVersionId === '') {
              updateWarnings('Scene is missing avatar');
            }
            if (scene.characterVersionId === '') {
              updateWarnings('Scene is missing character');
            }
            if (
              checkContentVersion(
                experience.content_version,
                CONTENT_VERSION_FLAGS.genericRig,
              )
            ) {
              if (
                scene.characterStance === '' ||
                scene.startingBodyLanguage === '' ||
                scene.startingFacialExpression === ''
              ) {
                updateWarnings(
                  'Scene is missing one or more animations for character',
                );
              }
            } else if (scene.startingAnimationNameForCharacter === '') {
              updateWarnings('Scene is missing animation for character');
            }
          }

          // Sort display icons and status warnings
          if (mandatoryCount > 0) {
            icon = 'Exclamation';
            status = mandatoryStatus;
            if (mandatoryCount > 1) {
              status = 'Multiple required fixes...';
            }
          } else if (warningCount > 0) {
            if (warningCount > 1) {
              status = 'Multiple warnings...';
            }
            icon = 'Warning';
          }

          const errorCheckEntry = {
            name: emptyName ? 'NO NAME' : data.name,
            type: type as NodeType,
            status,
            operationId: data.id,
            icon,
          };

          return errorCheckEntry;
        };

        [...operationEntries, ...sceneEntries].forEach((op) => {
          const errorCheckEntry = getErrorCheckEntryFromOperation(op);
          if (errorCheckListByType[errorCheckEntry.type]) {
            errorCheckListByType[errorCheckEntry.type] = [
              ...errorCheckListByType[errorCheckEntry.type],
              errorCheckEntry,
            ];
          } else {
            errorCheckListByType[errorCheckEntry.type] = [errorCheckEntry];
          }
        });

        const requireExperienceTesting = yield* call(() =>
          getToggleFromLDClient<boolean>(
            'in-headset-testing-is-publishing-prerequisite',
            false,
          ),
        );

        // This array is for experience checklist items that are toggleable,
        // i.e. can be hidden behind a feature flag.
        const toggleableExpChecklistItems: (
          | ExperiencePropertiesData
          | false
          | undefined
          | null
        )[] = [
          requireExperienceTesting && {
            name: 'Current Version Passed Testing',
            icon:
              !!experience.experienceTestInfo &&
              experience.experienceTestInfo.versionId ===
                experience.versionId &&
              experience.experienceTestInfo.status === 'passed'
                ? 'Check'
                : 'Exclamation',
          },
        ];

        const expErrorCheckList: ExperiencePropertiesData[] = [
          {
            name: 'Experience Name',
            icon:
              experience.name.match(/^ *$/) === null ? 'Check' : 'Exclamation',
          },
          {
            name: 'Image',
            icon: experience.image ? 'Check' : 'Exclamation',
          },
          {
            name: 'Description',
            icon:
              experience.description.match(/^ *$/) === null
                ? 'Check'
                : 'Exclamation',
          },
          {
            name: 'Estimated Training Time',
            icon: experience.durationInMinutes > 0 ? 'Check' : 'Exclamation',
          },
          // The error checklist will only include requirements that are toggled on
          ...toggleableExpChecklistItems.filter(
            (v): v is ExperiencePropertiesData => !!v,
          ),
        ];

        expErrorCheckList.forEach((data) => {
          if (data.icon === 'Exclamation') {
            totalMandatoryWarnings++;
          }
        });

        yield* put(
          experienceReducer.actions.update({
            expErrorCheckList,
            scenesAndOperationsErrorCheck: {
              ...errorCheckListByType,
            },
            publishStatus:
              totalMandatoryWarnings === 0
                ? totalWarnings > 0
                  ? 'Warning'
                  : 'Check'
                : 'Exclamation',
          }),
        );
      },
    },

    getExperienceTestResults: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const field = 'getExperienceTestResults';
        const state: State = yield* select();
        const { experience } = state.main;

        const currVersionId = experience.versionId;

        if (!currVersionId) {
          return;
        }

        const currTestInfo = experience.experienceTestInfo;

        if (
          !!currTestInfo &&
          currTestInfo.versionId === currVersionId &&
          (currTestInfo.status === 'passed' || currTestInfo.status === 'failed')
        ) {
          return;
        }

        let testsFound: AssetTestModel[] = [];

        try {
          const request = AssetManagementApi().testing.getAssetTests(
            currVersionId,
            'version_id',
          );
          const response = yield* call(request);

          testsFound = response.data;
        } catch (e) {
          logSagaError(action, e);
          yield* put(
            experienceReducer.actions.update({
              experienceTestInfo: undefined,
            }),
          );
          yield* put(
            experienceReducer.actions.updateErrors({
              field,
              error: `Getting experience test results failed: ${
                isAxiosError(e) ? e.response?.data : JSON.stringify(e)
              }`,
            }),
          );
        }

        if (testsFound.length < 1) {
          try {
            yield* call(
              AssetManagementApi().testing.queueAssetForTesting(currVersionId),
            );
            yield* put(
              experienceReducer.actions.update({
                experienceTestInfo: {
                  versionId: currVersionId,
                  status: 'queued',
                },
              }),
            );
          } catch (e) {
            logSagaError(action, e);
            yield* put(
              experienceReducer.actions.update({
                experienceTestInfo: undefined,
              }),
            );
            yield* put(
              experienceReducer.actions.updateErrors({
                field,
                error: `Queueing the experience for testing failed: ${
                  isAxiosError(e) ? e.response?.data : JSON.stringify(e)
                }`,
              }),
            );
          }
        } else {
          const statusPriority: Record<AssetTestResult, number> = {
            failed: 0,
            passed: 1,
            testing: 2,
            queued: 3,
            requeued: 4,
          };

          const testToUse = testsFound.reduce((testToUse, currTest) => {
            if (
              statusPriority[currTest.test_result] <
                statusPriority[testToUse.test_result] ||
              (currTest.place_in_queue ?? -1) < (testToUse.place_in_queue ?? -1)
            ) {
              return currTest;
            }

            return testToUse;
          }, testsFound[0]);

          yield* put(
            experienceReducer.actions.update({
              experienceTestInfo: {
                versionId: currVersionId,
                status: testToUse.test_result,
                resultData: testToUse.test_result_data,
                placeInQueue: testToUse.place_in_queue,
              },
            }),
          );
        }

        const currentSaga: {
          actions: {
            setErrorCheckListFromExperience: ActionCreatorWithPayload<void>;
          };
        } = sliceSaga;

        yield put(currentSaga.actions.setErrorCheckListFromExperience());
      },
    },
  },
});

export default sliceSaga;
