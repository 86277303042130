import { logToServer } from './logging';
import { isAxiosError } from 'axios';
import { Action } from 'redux';

export const logSagaError = (action: Action, error: unknown): void => {
  const axiosError = isAxiosError(error);
  if (axiosError) {
    if (error.response?.config?.headers?.authorization)
      error.response.config.headers.authorization = '[redacted]';
  }

  if (error instanceof Error || axiosError) {
    logToServer('error', `${action.type} failed`, {
      action,
      error: {
        message: error.message,
        response: axiosError ? error.response : undefined,
        stack: error.stack,
      },
    });
  }
};

// Using this for signed urls works around an issue with fetch in Chromium browsers
// See https://strivr.slack.com/archives/C016WDV935M/p1704827097904049
export const xhrDownload = (url: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.onerror = () => {
    logToServer('error', 'XHR download failed', {
      url,
    });
  };
  xhr.send();
};
